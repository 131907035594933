// inspired by https://stackoverflow.com/a/2270987
export const getConsecutiveDayRanges = (array: number[]): string[] => {
	const ranges: string[] = [];
	for (let i = 0; i < array.length; i++) {
		const rangeStart = array[i];
		let rangeEnd = rangeStart;

		// while within the range, increment
		while (array[i + 1] - array[i] === 1) {
			rangeEnd = array[i + 1]; // increment the index if the numbers sequential
			i++;
		}

		ranges.push(rangeStart == rangeEnd ? rangeStart + '' : rangeStart + '-' + rangeEnd);
	}

	return ranges;
};
