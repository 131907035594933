import { OptionProp } from '@vakantiesnl/components/src/__LEGACY__/atoms/SelectBox';

const flattenDurationRanges = (durations: OptionProp[]): string[] =>
	durations.reduce((prev: string[], option) => [...prev, ...option.value.split(',')], []);

const formatDurationDaysOptions = (ranges: string[], label: string): OptionProp[] =>
	ranges
		? ranges.map((day: string) => ({
				title: `${day} ${label}`,
				value: day,
			}))
		: [];

export const formattedDurationDaysOptions = (durations: OptionProp[], label: string): OptionProp[] =>
	formatDurationDaysOptions(flattenDurationRanges(durations), label);
